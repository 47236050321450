export default function getFormattedDate(date, time = false) {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  const day = date.getDate().toString().padStart(2, '0')
  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return time ? `${year}-${month}-${day} ${hours}:${minutes}` : `${year}-${month}-${day}`
}

export function getFormattedDateMonth(date) {
  const year = date.getFullYear()
  const month = (1 + date.getMonth()).toString().padStart(2, '0')
  return `${year}-${month}`
}

export function getFormattedDateTime(datetime, locale) {
  const date = new Date(datetime)

  if (locale === 'en') {
    return date.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }

  const hours = date.getHours().toString().padStart(2, '0')
  const minutes = date.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export function getFormattedDateTimeZone(datetime, locale) {
  const date = new Date(datetime)
  // const offset = date.getTimezoneOffset() * 60000
  // const localDate = new Date(date.getTime() + offset)
  const localDate = date

  if (locale === 'en') {
    return localDate.toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }

  const hours = localDate.getHours().toString().padStart(2, '0')
  const minutes = localDate.getMinutes().toString().padStart(2, '0')
  return `${hours}:${minutes}`
}

export function getFormattedDateTimeRelative(datetime, locale) {
  const msPerMinute = 60 * 1000
  const msPerHour = msPerMinute * 60
  const msPerDay = msPerHour * 24
  const msPerMonth = msPerDay * 30
  const msPerYear = msPerDay * 365

  const elapsed = new Date(Date.now()) - new Date(datetime)

  const rtf = new Intl.RelativeTimeFormat(locale, { style: 'long' })

  if (elapsed < msPerMinute) {
    return rtf.format(-Math.round(elapsed / 1000), 'second')
  }

  if (elapsed < msPerHour) {
    return rtf.format(-Math.round(elapsed / msPerMinute), 'minute')
  }

  if (elapsed < msPerDay) {
    return rtf.format(-Math.round(elapsed / msPerHour), 'hour')
  }

  if (elapsed < msPerMonth) {
    return rtf.format(-Math.round(elapsed / msPerDay), 'day')
  }

  if (elapsed < msPerYear) {
    return rtf.format(-Math.round(elapsed / msPerMonth), 'month')
  }

  return rtf.format(-Math.round(elapsed / msPerYear), 'year')
}

export function applyTimeZoneOffset(hours, isNormalized) {
  const offset = new Date().getTimezoneOffset() / 60

  function normalizeTime(hour) {
    let normalizedHour = hour + offset

    if (normalizedHour < 0) {
      normalizedHour += 24
    } else if (normalizedHour >= 24) {
      normalizedHour -= 24
    }

    return normalizedHour
  }

  function denormalizeTime(hour) {
    let denormalizedHour = hour - offset

    if (denormalizedHour < 0) {
      denormalizedHour += 24
    } else if (denormalizedHour >= 24) {
      denormalizedHour -= 24
    }

    return denormalizedHour
  }

  if (isNormalized) {
    return hours.map(hour => denormalizeTime(hour))
  }

  return hours.map(hour => normalizeTime(hour))
}

export function getDateMinusDays(days) {
  const currentDate = new Date()
  currentDate.setDate(currentDate.getDate() - days)

  return currentDate
}
