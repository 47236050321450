<template>
  <b-row class="content-header">
    <b-col
      class="content-header-left mb-2"
      cols="12"
    >
      <b-row class="breadcrumbs-top">
        <b-col cols="12">
          <h2
            v-if="pageTitle"
            class="content-header-title float-left pr-1 mb-0"
          >
            {{ pageTitle }}
            <small v-if="pageSubtitle !== ''">{{ pageSubtitle }}</small>
          </h2>
          <div class="breadcrumb-wrapper">
            <b-breadcrumb>
              <b-breadcrumb-item to="/">
                <feather-icon
                  icon="HomeIcon"
                  size="16"
                  class="align-text-top"
                />
              </b-breadcrumb-item>
              <b-breadcrumb-item
                v-for="(item, idx) in breadcrumbItems"
                :key="`${item.text}_${idx}`"
                :active="item.active"
                :to="item.to"
              >
                {{ isTranslated(item.text) }}
              </b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BBreadcrumb,
  BBreadcrumbItem,
  BRow,
  BCol,
} from 'bootstrap-vue'

export default {
  components: {
    BBreadcrumb,
    BBreadcrumbItem,
    BRow,
    BCol,
  },
  props: {
    pageTitle: {
      type: String,
      default: '',
    },
    pageSubtitle: {
      type: String,
      default: '',
    },
    breadcrumbItems: {
      type: Array,
      required: true,
    },
  },
  methods: {
    isTranslated(textKey) {
      if (this.$te(`breadcrumbs.${textKey}`)) {
        return this.$t(`breadcrumbs.${textKey}`)
      }
      if (this.$te(textKey)) {
        return this.$t(textKey)
      }
      return textKey
    },
  },
}
</script>
<style scoped>
@media screen and (max-width: 1200px) {
  .content-header-title small {
    display: block;
  }
}
</style>
