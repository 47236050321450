<template>
  <div>
    <breadcrumbs
      :breadcrumb-items="breadcrumbItems"
    />
    <b-card>
      <div class="head-card">
        <div class="head-card-top">
          <h4>{{ $t('page.notifications') }}</h4>
          <feather-icon
            v-b-tooltip.hover.top="$t('Close')"
            icon="XIcon"
            size="18"
            class="toggle-close cursor-pointer"
            @click="$router.go(-1)"
          />
        </div>
        <hr>
      </div>
      <template v-if="totalRows">
        <app-timeline>
          <app-timeline-item
            v-for="notification in pageNotifications"
            :key="notification._id"
            :icon="`${icons[notification.model.type][notification.model.action]}Icon`"
            :variant="notification.model.is_moderation ? 'warning' : 'success'"
          >
            <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
              <div>
                <h6>{{ $t(`notifications.${notification.model.type}.${notification.model.action}`) }} {{ notification.amount ? `($${notification.amount})` : '' }}</h6>
                <span class="mt-15">
                  <router-link :to="linkToEntity(notification)">
                    {{ $t(`notifications.target.${notification.model.type}`) }}
                  </router-link>
                  {{ notification.model.is_moderate }}
                  <b-badge
                    v-if="notification.model.is_moderation"
                    class="ml-25"
                    variant="light-warning"
                  >
                    {{ $t('notifications.moderation') }}
                  </b-badge>
                </span>
              </div>
              <small class="text-right">{{ getFormattedDateTime(notification.datetime, $i18n.locale) }}<br><span class="text-muted">{{ getFormattedDateTimeRelative(notification.datetime, $i18n.locale) }}</span></small>
            </div>
            <div class="mt-25 d-flex flex-sm-row flex-column justify-content-between align-items-start">
              <div
                v-if="notification.who.type === 'system'"
                class="mb-1 mb-sm-0"
              >
                <span class="text-secondary mb-50">{{ $t('notifications.source', { source: $t(`notifications.who.${notification.who.type}`) }) }}</span>
              </div>
              <div
                v-else
                class="mb-1 mb-sm-0"
              >
                <router-link :to="{name: 'dashboard-users-page', params: { userId: notification.who.oid } }">
                  <span class="text-secondary mb-50">{{ $t('notifications.source', { source: $t(`notifications.who.${notification.who.type}`) }) }}</span>
                </router-link>
              </div>
            </div>
          </app-timeline-item>
        </app-timeline>
        <div class="mt-1">
          <hr>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="right"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </template>
    </b-card>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import Breadcrumbs from '@/views/ui/breadcrumbs/Breadcrumbs.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { getFormattedDateTime, getFormattedDateTimeRelative } from '@/utils/date-formatter'
import {
  BCard,
  BBadge,
  BPagination,
  VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    Breadcrumbs,
    BCard,
    BBadge,
    BPagination,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      rows: [],
      breadcrumbItems: [],
      currentPage: 1,
      perPage: 24,
      icons: {
        account: {
          create: 'User',
          edit: 'Edit',
          paypal_payment: 'CreditCard',
        },
        payment: {
          create: 'User',
          edit: 'Edit',
          payment: 'CreditCard',
        },
        site: {
          create: 'Plus',
          edit: 'Edit',
          moderation: 'Check',
        },
        widget: {
          create: 'Plus',
          edit: 'Edit',
          moderation: 'Check',
        },
        campaign: {
          create: 'Plus',
          edit: 'Edit',
          delete: 'Trash2',
          moderation: 'Check',
          payment: 'CreditCard',
        },
        creative: {
          create: 'Plus',
          edit: 'Edit',
          delete: 'Trash2',
          moderation: 'Check',
        },
      },
      getFormattedDateTime,
      getFormattedDateTimeRelative,
    }
  },
  computed: {
    startIndex() {
      return (this.currentPage - 1) * this.perPage
    },
    endIndex() {
      return this.currentPage * this.perPage
    },
    pageNotifications() {
      return this.rows.slice(this.startIndex, this.endIndex)
    },
    totalRows() {
      return this.rows.length
    },
  },
  created() {
    this.setBreabcrumbs()
    this.fetchNotifications()
  },
  methods: {
    async fetchNotifications() {
      const responseData = await useJwt.getNotifications()
      this.rows = responseData.data.notifications || []
    },
    linkToEntity(params) {
      const { type } = params.model
      if (type === 'widget') {
        return {
          name: 'dashboard-site-widget-edit',
          params: {
            userId: params.model.account_id,
            siteId: params.model.site_id,
            widgetId: params.model.oid,
          },
        }
      }
      if (type === 'site') {
        return {
          name: 'dashboard-sites-edit',
          params: {
            userId: params.model.account_id,
            siteId: params.model.oid,
          },
        }
      }
      if (type === 'campaign') {
        return {
          name: 'dashboard-campaigns-edit',
          params: {
            userId: params.model.account_id,
            id: params.model.oid,
          },
        }
      }
      if (type === 'creative') {
        return {
          name: 'dashboard-campaigns-creative-edit',
          params: {
            userId: params.model.account_id,
            id: params.model.campaign_id,
            bannerId: params.model.oid,
          },
        }
      }
      if (type === 'account' && params.amount !== undefined) {
        return {
          name: 'dashboard-users-transaction',
          params: {
            userId: params.model.oid,
          },
        }
      }
      return {
        name: 'dashboard-users-edit',
        params: {
          userId: params.model.oid,
        },
      }
    },
    setBreabcrumbs() {
      this.breadcrumbItems = [
        {
          text: this.$i18n.t('page.notifications'),
          active: true,
        },
      ]
    },
  },
}
</script>
